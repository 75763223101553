import React from 'react';
import './footer.css';

const Footer = ({ setActiveComponent }) => {
  const handleComponentChange = (component) => {
    setActiveComponent(component);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <footer className="footer">
      <div className="footer-section brand">
        <h3>P. RAKESH INDUSTRIES</h3>
        <h3>linking the BIG</h3>
      </div>

      <div className="footer-section contact">
        <h3>Contact Us</h3>
        <p>Address: 10, Sadguru Industrial Estate, Near N.H. No. 8, Killa-Pardi, Dist-Valsad, Gujarat, India-396125</p>
        <p>Email: paresh.bharatia@pri.org.in, marketing@pri.org.in</p>
      </div>

      <div className="footer-section explore">
        <h3>Explore Our Site</h3>
        <ul>
          <li onClick={() => handleComponentChange('Homepage')}>Home</li>
          <li onClick={() => handleComponentChange('OurProducts')}>Our Products</li>
          <li onClick={() => handleComponentChange('SparesAndServices')}>Spares and Services</li>
        </ul>
      </div>

      <div className="footer-copyright">
      <p>
    Copyright © {new Date().getFullYear()} | 
     <a href="https://www.instagram.com/tattvaz?igsh=MWJtNnR3OGowaTZhdw==" target="_blank" rel="noopener noreferrer" style={{color:"white"}}>Design by Tattvaz</a>
  </p>
      </div>
    </footer>
  );
};

export default Footer;
