import React from 'react';
import './presserformer.css';
import imggg from '../../image/imggg.png';
import img1 from '../../image/img1.png'
import imgggg from '../../image/imgggg.png'
import flip from '../../image/flip.png'
import pf from '../../image/pf.png'
import pff from '../../image/imggCopy.png'
const PressureFormer = () => {
  return (
    
<div>



    <div className="pressure-former">
   





    <div className="pressure-former-container" >
  
  <div className="formerdesktop"> 
    <div className="former-container" id="about" >
      {/* Left Side */}
      <div className="about-imagee">
        <div>
          {/* New Two-Column Layout */}
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {/* Left Column */}
            <div style={{ flex: '2', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={imgggg} alt="Left Image" style={{ maxWidth: '100%', height: '550px' }} />
            </div>
            {/* Right Column */}
            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <img src={flip} alt="Top Image" style={{ maxWidth: '860%', height: 'auto' ,width:"400px"}} />
              <img src={imggg} alt="Bottom Image" style={{ maxWidth: '400%', height: 'auto',width:"200px" }} />
            </div>
          </div>

          {/* Original Content */}
         <div >
  <h2
    style={{
      color: "#4d7544",
      marginTop: "30px",
      textAlign: "left",
      fontSize: "26px",
    }}
  >
    Key Features:
  </h2>
  <ul
    style={{
      color: "black",
      textAlign: "left",
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)", // Two columns
      gap: "10px", // Space between items
      listStyleType: "disc", // Optional: ensures bullet points
     paddingLeft:"10px",
      margin: "0",
    }}
  >
    <li>
    Improved sheet strength
    </li>
    <li>Excellent sheet formation at all speeds and substances</li>
    <li>
    MD/CD sheet gain ratio control
    </li>
    <li>Unaffected by normal freeness changes </li>
    <li>Higher speed capabilities </li>
    <li>Approaches fourdrinier type formation </li>
    <li>Basis weight and speed changes easily and quickly accomplished</li>
    <li>Superior sheet quality and test versus standard cylinder forming techniques</li>
    <li>Easy to maintain</li>
  </ul>
</div>

        </div>
      </div>

      {/* Right Side */}
      <div className="about-text">
      <h2 style={{color:"#4d7544", textAlign:"right", fontSize:"40px",marginTop:"50px"}} className='pressure-former-desktopresponsiveview'>PRESSURE FORMER</h2>
       
    
<div style={{marginTop:"200px"}}>
<img src={pff} alt="Mission" height="250px" style={{ display: "flex", justifyContent: "flex-end",float:"right",marginLeft:"10px" ,marginTop:"-130px"}}/>

<p
              style={{
                backgroundColor: "#4d7544",
                borderRadius: "10px",
                color: "white",
                paddingLeft: "20px",
                paddingBottom: "20px",
                paddingRight: "20px",
                paddingTop: "20px",
                width: "100%",
                boxSizing: "border-box",
                textAlign: "left",
                
              }}
            >
              <span className="dot"></span>The cylinder mould pressure former is one of the most efficient technologies for multilayer paper and board manufacturing. Each unit is a complete system and can be combined with identical units to make a multilayer board-forming system.
              <br />
              <br />
              <span className="dot"></span> PRI Pressure Former is capable of producing boards with minimum cross-profile variation at higher speeds. PRI Pressure Former can also produce specialty paper as well as bond paper, cup stock, and equivalent papers. We provide our technical support for various products.
              <br />
              <br />
              <span className="dot"></span> The forming head is designed for a wide range of operations for producing various types of boards. To cope with changes in the GSM range, the gap between the cylinder mould and the forming lip can be adjusted by a precise micrometer provided at both ends. The PRI Pressure Former is also equipped with a floating throat adjuster operated by handwheel. The inlet centrifugal distributor is highly finished internally for smooth pulp entry into the former explosion chamber. The centrifugal distributor is equipped with dilution nozzles, which further minimizes cross-profile variation on board. As an option for customers, PRI also offers Taper manifold instead of centrifugal distributor.
              <br />
              <br />
              <span className="dot"></span> The PRI forming unit and cylinder mould are enclosed in a vat, which avoids water splashing at higher speeds while keeping the floor clean and dry for trouble-free operation. The vat is also equipped with an air extraction pipe on the side wall for air evacuation from the cylinder mould.

            </p>
</div>
       
      </div>
    </div>
  </div>
</div>


    </div>







<div className='hideindesktop'>
<div className="details-container">
      
      <div className="details-content">
        {/* Left Column */}
        <div className="column left">
        <h2 style={{color:"#4d7544"}}  className='mobileresponsiveview'>PRESSURE FORMER</h2>
        <div>
           
        <div
    style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}
    className="imgcenter"
  >
    <img src={imgggg} alt="Mission" className="responsive-img" />
    <img src={flip} alt="Mission" className="responsive-img" />

    <img src={imggg} alt="Mission" className="responsive-img" />
  </div>
              
  
           <div className="image-container">
            {/* <div className="image-item">
              <img src={blade11} alt="Image 1" className="responsive-image" />
            </div> */}
           
          </div>
          
          
                    <div className='keypadding'>
                    <h2 style={{color:"#4d7544",marginTop:"80px",textAlign:"left",fontSize:"26px"}}>Key Features:</h2>
                    <ul style={{color:"black",textAlign:"left"}}>
                    <li>
    Improved sheet strength
    </li>
  <br></br>  <li>Excellent sheet formation at all speeds and substances</li>
  <br></br>  <li>
    MD/CD sheet gain ratio control
    </li>
    <br></br> <li>Unaffected by normal freeness changes </li>
    <br></br> <li>Higher speed capabilities </li>
    <br></br> <li>Approaches fourdrinier type formation </li>
    <br></br> <li>Basis weight and speed changes easily and quickly accomplished</li>
    <br></br><li>Superior sheet quality and test versus standard cylinder forming techniques</li>
    <br></br><li>Easy to maintain</li>
                
                    </ul>
                    </div>
                    </div>
        </div>
  
        {/* Right Column */}
        <div className="column right">
        <div className="column-right">
        <h2 style={{color:"#4d7544", marginTop:"-10px",textAlign:"left",fontSize:"40px"}}  className='desktopresponsiveview'>BLADE / BAR COATER</h2>
  
        <p
  style={{
    backgroundColor: "#4d7544",
    borderRadius: "10px",
    color: "white",
    paddingLeft: "20px",
    paddingBottom: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
    width: "100%",
    boxSizing: "border-box", // ensures padding does not affect the width
  marginTop:"40px",
  textAlign:"left"
  }}
  >
   <span className="dot"></span> The cylinder mould pressure former is one of the most efficient technologies for multilayer paper and board manufacturing. Each unit is a complete system and can be combined with identical units to make a multilayer board-forming system.
              <br />
              <br />
              <span className="dot"></span> PRI Pressure Former is capable of producing boards with minimum cross-profile variation at higher speeds. PRI Pressure Former can also produce specialty paper as well as bond paper, cup stock, and equivalent papers. We provide our technical support for various products.
              <br />
              <br />
              <span className="dot"></span> The forming head is designed for a wide range of operations for producing various types of boards. To cope with changes in the GSM range, the gap between the cylinder mould and the forming lip can be adjusted by a precise micrometer provided at both ends. The PRI Pressure Former is also equipped with a floating throat adjuster operated by handwheel. The inlet centrifugal distributor is highly finished internally for smooth pulp entry into the former explosion chamber. The centrifugal distributor is equipped with dilution nozzles, which further minimizes cross-profile variation on board. As an option for customers, PRI also offers Taper manifold instead of centrifugal distributor.
              <br />
              <br />
              <span className="dot"></span> The PRI forming unit and cylinder mould are enclosed in a vat, which avoids water splashing at higher speeds while keeping the floor clean and dry for trouble-free operation. The vat is also equipped with an air extraction pipe on the side wall for air evacuation from the cylinder mould.
 </p>
  
      </div>
      </div>
    </div>
  </div>

  </div>




</div>
  );
};

export default PressureFormer;
