import React, { useState } from 'react';
import './Carousel.css';
import img1 from '../../image/gallery1.png';
import img2 from '../../image/gallery2.png';
import img3 from '../../image/gallery3.png';
import img4 from '../../image/gallery4.png';
import img5 from '../../image/gallery5.png';
import img6 from '../../image/gallery6.png';
import img7 from '../../image/gallery7.png';
import img8 from '../../image/gallery8.png';
import img9 from '../../image/gallery9.png';
import img10 from '../../image/gallery10.png';

const Carousel = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8];
  const [currentIndex, setCurrentIndex] = useState(0);

  const isMobile = window.innerWidth <= 767;
  const isTablet = window.innerWidth > 767 && window.innerWidth <= 1023;

  const visibleImageCount = isMobile || isTablet ? 1 : 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const visibleImages = images
    .slice(currentIndex, currentIndex + visibleImageCount)
    .concat(images.slice(0, Math.max(0, visibleImageCount - (images.length - currentIndex))));

  return (
    <div className="carousel-container">
      <h2 className="gallery-title" style={{ marginTop: '50px' }}>Gallery</h2>
      <div className="carousel">
      <button className="carousel__arrow right" onClick={handlePrev} >
      &#8592;
        </button>
       
        <div className="carousel__track">
          {visibleImages.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Slide ${index + 1}`}
              className="carousel__image"
            />
          ))}
        </div>
        <button className="carousel__arrow right" onClick={handleNext}>
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default Carousel;
