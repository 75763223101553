import React, { useState, useEffect } from 'react';
import './Landingsection.css';

// Import images
import img1 from '../../image/d1.png';
import img2 from '../../image/d2.png';
import img3 from "../../image/d3.png"

const Landingsection = () => {
  // Array of images
  const images = [img1, img2, img3];

  // State for current image index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Effect to change the image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <div
      className="landing-page"
      style={{ backgroundImage: `url(${images[currentIndex]})` }}
    >
      <div className="overlay">
        <div className="text-container">
          <h1>PAPER AND BOARD MACHINES</h1>
          <h1>linking the BIG</h1>
        </div>
      </div>
    </div>
  );
};

export default Landingsection;
