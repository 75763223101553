import React from 'react';
import './Sparesandservices.css'; // Ensure you create this CSS file

const Sparesandservices = () => {
  const services = [
    {
      title: "Re-grinding of PRI Cylinder Mould",
      description:
        "We offer precision re-grinding services for PRI cylinder moulds, enhancing their operational efficiency and extending service life.",
      
    },
    {
      title: "Rubber Coating of Backing Roll & Couch Roll",
      description:
        "Our advanced rubber coating services improve the surface properties of backing and couch rolls, enhancing adhesion and reducing wear.",
       
    },
    {
      title: "Hard Chrome Plating",
      description:
        "We specialize in hard chrome plating for applicator rolls and paper rolls, providing superior resistance to abrasion and corrosion, thereby enhancing longevity.",
      
    },
    {
      title: "Troubleshooting of PRI make Coater & Pressure Former",
      description: (
        <>
          Our skilled technicians offer diagnostic services for coater and pressure former systems upon request, identifying and rectifying performance issues efficiently. 
          <span style={{ color: "#4d7544" }}><b>(On Request)</b></span>
        </>
      ),
    },
    
    {
      title: "Quality Setting of PRI make Coater & Pressure Former",
      description: (
        <>
          We provide expert quality setting services to optimize the operational parameters of coater and pressure former systems, ensuring consistent production quality.{" "}
          <span style={{ color: "#4d7544" }}><b>(On Request)</b></span>
        </>
      ),
    },
  ];    
  const spares = [
    {
      category: "Coater Components",
      items: [
        "Rubber Bar Bed",
        "Metering Bar",
        "Metering Bar Universal Joint",
        "Edge Doctors",
        "Backing Roll & Applicator Roll",
        "Coater Blade",
      ],
      color: "#4d7544",
    },
    {
      category: "Pressure Former Components",
      items: [
        "Deckle Seal / End Seal (HDPE)",
        "Bottom Lip / Sealing Lip (HDPE)",
        "V-seal / Rubber Seal for Cylinder Mould",
        "Sealing Tube & Pad",
        "Wire Clothing Fixtures",
      ],
      color: "#4d7544",
    },
    {
      category: "Coating Kitchen Equipment",
      items: [
        "Rotor Disc for Coating Disperser",
        "Impeller for Storage/Service Tank",
        "Impeller for P.V.A Cooker",
      ],
      color: "#4d7544",
    },
    {
      category: "Size Press Components",
      items: [
        "Spares Rolls",
        "Universal Joint for Metering Size Press",
      ],
      color: "#4d7544",
    },
  ];

  return (
    <div className="spare-container">
    <div className="spare-content">
    <h1>Spares and Services</h1>
    <p>
    At P. Rakesh Industries, we offer precision-engineered spare parts and top-notch services to keep your OEM paper mill machinery running smoothly. Our high-quality spares ensure durability and performance, while our expert service team provides reliable maintenance and repair solutions. Count on us for all your spares and service needs to maintain optimal machine efficiency.
    </p>


    <h1>Spares:</h1>
    <p>
      We maintain an extensive inventory of precision spare parts to support the functionality of your machinery:
    </p>
    <div className="spares">
      {spares.map((spare, index) => (
        <div className="spare-card" key={index} style={{ borderColor: spare.color }}>
          <div className="spare-card-header" style={{ backgroundColor: spare.color }}>
            <h3 style={{fontSize:"20px"}}>{spare.category}</h3>
          </div>
          <ul>
            {spare.items.map((item, idx) => (
              <li key={idx} style={{textAlign:"left" }}>• {item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>



    <h1 style={{marginTop:"80px"}}>Our Services:</h1>
    <div className="services">
      {services.map((service, index) => (
        <div className="service-card" key={index} style={{ borderColor: service.color }}>
          <div className="service-card-header" >
            <h3 style={{ color:"black" ,fontSize:"20px"}}>{service.title}</h3>
          </div>
          <p>{service.description}</p>
        </div>
      ))}
    </div>

    <section className="contact-us-section" style={{marginTop:"30px"}}>
    <h2 style={{color:"#4d7544"}}>Contact Us (only for Spares and Services)</h2>
    <p className="contact-text">
      For inquiries regarding our spares and services, or to place an order, please contact us:
    </p>
    <div className="contact-details">
      <h3 style={{color:"#4d7544"}}>Email:</h3>
      <p>poojan.bharatia@pri.org.in</p>
      <p>      paresh.bharatia@pri.org.in</p>
    </div>
  </section>
    </div>
  </div>
  );
};


export default Sparesandservices;
